import { TOCPageProps } from "../model";
import { Atom, Cmp, Mod } from ":mods";
import { createEffect, createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import { updateShowExhibition } from "../apis/show-exhibition";

export function Terms({ stage_id, terms, agreements }: TOCPageProps) {
  const [termsAccepted, setTermsAccepted] = createSignal([]);
  const [enableButton, setEnableButton] = createSignal(false);
  const [tc_data, setTcData] = createStore({
    key: "box-01",
    tc_agree: false,
  });

  createEffect(() => {
    if (
      termsAccepted().length > 0 &&
      termsAccepted().every((t) => t?.agreed) &&
      termsAccepted().length == agreements.length
    ) {
      setEnableButton(() => true);
      setTcData("tc_agree", true);
    } else {
      setEnableButton(() => false);
      setTcData("tc_agree", false);
    }
  });

  const handleAgreements = ({ currentTarget }) => {
    const id = currentTarget?.value;
    const currentTerm = termsAccepted()?.find((term: any) => term?.id == id);
    const obj = { id: id, agreed: currentTerm?.agreed ? false : true };
    if (!currentTerm) {
      setTermsAccepted([...termsAccepted(), obj]);
    } else {
      setTermsAccepted([...termsAccepted().filter((term: any) => term.id != currentTerm?.id), obj]);
    }
  };
  const handleClick = async () => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(tc_data));
    await updateShowExhibition(stage_id, formData).then((message) => {
      console.log(message, " :message");
      Cmp.Alert.actions.pushToast({
        type: "success",
        message: "Terms and Condition Updated successfully",
      });
    });
  };

  return (
    <main class="flex flex-col items-center w-full justify-between">
      <section class="flex flex-col gap-40px w-full pb-80px pt-40px">
        {terms.map((term) => {
          return (
            <article>
              <h1 class="!font-700">{term.title.toUpperCase()}</h1>
              <p class="text-16px">{term.description}</p>
            </article>
          );
        })}
        {agreements?.map((agreement) => {
          return (
            <div class="flex gap-13px text-16px">
              <input
                class="w-25px h-25px cursor-pointer"
                type="checkbox"
                value={agreement.id}
                checked={agreement.checked}
                onClick={handleAgreements}
              />
              <p>{agreement.text}</p>
            </div>
          );
        })}
        <div class="flex justify-end">
          <Atom.Form.Button
            controls={[]}
            class="form-btn px-4 py-1 !w-fit rounded-lg"
            statusValid="Save"
            statusInvalid="Save"
            onlyStatus={["valid", "submit", "invalid"]}
            onclick={handleClick}
            classes={{
              spinner: "!min-w-80px",
            }}
            triggers={{
              manualStatus: enableButton() ? "valid" : "invalid",
            }}
          />
        </div>
      </section>
    </main>
  );
}
